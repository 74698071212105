import React from "react";
import ReactDOM from "react-dom";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

import { HashRouter as Router } from "react-router-dom";
import "./assets/base.css";
import Main from "./Pages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/AuthProvider";

import './App.css';

const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store} className="App">
      <AuthProvider>
        <Router>
          <Component />
        </Router>
      </AuthProvider>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
unregister();

// registerServiceWorker();
