import { React,  Fragment } from "react";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";

import LogoZletric from "../../assets/logo_v4.png";

export const SemPermissao = () => {
  return (
    <Fragment>
      <MDBContainer className="p-1 my-3" >
        <MDBRow>
          <MDBCol> </MDBCol>
          <MDBCol  className="card p-5"  >    
            <img src={LogoZletric}  alt=""   width={380} />   
            <p><b>Você não tem acesso a este módulo !</b></p>
            <p>Por favor entre em contato com setor de TI da Zletric.</p>
            <p><a href="/">Login</a></p>
          </MDBCol>
          <MDBCol> </MDBCol>
         </MDBRow>
        </MDBContainer>
      </Fragment>
  )
}
