import { createContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [userLog, setUserLog] = useState("");
  const [page_default, setpage_default] = useState("");
  const [auth, setAuth] = useState(true);
  const [headers, setHeaders] = useState({});
  const [config, setConfig] = useState({});

  useEffect(() => { 
    }, [userLog]);

  useEffect(() => {
    if (localStorage.getItem("user") != null) {

      const user = JSON.parse(localStorage.getItem("user"));
    //  let userLog = JSON.parse(localStorage.getItem("user"));
      const headers = { Authorization: `Bearer ${user?.token}` };
     // const config = { headers: { Authorization: `Bearer ${user?.token}` } };
      const urlPadrao = process.env.REACT_APP_API_URL;

      let currentDate = new Date();
      try {
        setUserLog(JSON.parse(localStorage.getItem("user")));

      //  let userLog = JSON.parse(localStorage.getItem("user"));

        setHeaders({ Authorization: `Bearer ${user?.token}` });
        setConfig({ headers: { Authorization: `Bearer ${user?.token}` } });
        setpage_default(user.page_default);

        jwt_decode(user?.token);        
        //console.log("validade token = " + jwt_decode(user?.token).exp * 1000);

        //TODO: verifica token, se valido segue        
        axios.get(`${urlPadrao}/customers/getAuthProviderToken?token=${user?.token}}`, { headers }).then((res) => {                 
          if(res.data){
            setAuth(true); 
          }else{
            setAuth(false);
            return;
          }
        }); 
        setAuth(true);
        if (
          user == null ||
          user?.token == null ||
          jwt_decode(user?.token).exp * 1000 < currentDate.getTime()
        ) {
          setAuth(false);
          return;
        }
      } catch (error) {
        setAuth(false);
       // setpage_default('/login/');
      }
    } else {
      //console.log("Authprovider -> localStorage.getItem(user) == null");
     // window.location.reload(false);
      setAuth(false);
    //  setpage_default('/login/');
    
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth, userLog, page_default, headers, config }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
