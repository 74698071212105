import { Route, Routes } from "react-router-dom";
import React, {  lazy, Fragment } from "react";
import { ToastContainer } from "react-toastify";
import Logins from "../../Pages/Login/loginLazy";
import { SemPermissao } from "../../Pages/SemPermissao";

const Components = lazy(()  => import("../../Pages/Components"));

const AppMain = () => {

  return (
    <Fragment>
      <React.Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <h6 className="mt-5">
                Loading ...
                <small>Está carregando</small>
              </h6>
            </div>
          </div>
        }
      >
        {/* Rotas da aplicacao */}
        <Routes>
          <Route path="/"           element={<Logins/>} auth={true} />
          <Route path="/login/"    element={<Logins/>} auth={true} />

          <Route exact={false}  path="/cpo/" element={<Components/>}  auth={true} >

          <Route exact path={`dispositivos`} />
          <Route exact path={`dispositivos/add`} />
          <Route exact path={`dispositivos/edit/:id`} />
          <Route exact path={`dispositivos/edit/:id`} />

            <Route exact path={`locations`} />
            <Route exact path={`locations/add`} />
            <Route exact path={`locations/edit/:id`} />

            <Route exact path={`locationstype`} />
            <Route exact path={`locationstype/add`} />
            <Route exact path={`locationstype/edit/:id`} />

            <Route path={`transactions`} />
            <Route path={`transactions/view/:id`} />
            <Route path={`transactions/map`}/>
            <Route path={`transactions/qrcode`}/>
            <Route path={`transactions/ocpp`}/>
            

            <Route path={`transactions/power-graph`}/>

            <Route exact path={`device-group`}/>
            <Route exact path={`device-group/add`} />
            <Route exact path={`device-group/edit/:id`}/>

            <Route exact path={`device-membership`}/>
            <Route exact path={`device-membership/add`} />
            <Route exact path={`device-membership/edit/:id`}/>

            <Route exact path={`request-membership`}/>            
            <Route exact path={`request-membership/edit/:id`}/>

            <Route exact path={`wallets`}/>            
            <Route exact path={`wallets/edit/:id`}/>

            <Route path={`membership`} />
            <Route exact path={`membership/transactions`} />
            <Route exact path={`membership/transactions/view/:id`} />

            <Route exact path={`b2b/transactions`} />
            <Route exact path={`b2b/transactions/view/:id`} />
            <Route exact path={`b2b/dispositivos`} />

            <Route exact path={`sindico/transactions`} />
            <Route exact path={`sindico/transactions/view/:id`} />
            <Route exact path={`sindico/dispositivos`} />
            <Route exact path={`sindico/financeiro`} />

            <Route exact path={`administracao/roles`} />
            <Route exact path={`administracao/roles/add`} />
            <Route exact path={`administracao/roles/edit/:id`} />
            <Route exact path={`administracao/users`} />
            <Route exact path={`administracao/users/edit/:id`} />

            <Route exact path={`financeiro`} />
            <Route exact path={`financeiro/invoice`} />

            <Route exact path={`registration`} />
            <Route exact path={`registration/edit/:id`}/>

            <Route exact path={`corporateclient`} />
            <Route exact path={`corporateclient/add`} />
            <Route exact path={`corporateclient/edit/:id`} />

            <Route exact path={`realestate`}                  />
              <Route exact path={`realestate/add`}              />
              <Route exact path={`realestate/edit/:id`}        /> 


            {/* <Route exact path={`ocpp`} /> */}
            

            {/* modelos template*/}
            <Route path={`tabs`} />
            <Route path={`notifications`} />
            <Route path={`tooltips-popovers`} />
            <Route path={`progress-bar`} />
            <Route path={`carousel`} />
            <Route path={`modals`} />
            <Route path={`maps`} />
          </Route>
            <Route path={`semPermissao`} element={<SemPermissao />}/>
                
        </Routes>
      </React.Suspense>
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
