import { useRef, useState, useEffect, Fragment } from "react";

import { Button, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LogoZletric from "../../assets/logo_v4.png";
import "../Login/index.css";
import { InfinitySpin } from "react-loader-spinner";

export default function Login() {

  const navigate = useNavigate();
  const urlPadrao = process.env.REACT_APP_API_URL;

  const userRef = useRef();
  const errRef = useRef();

  //Estado do Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => { 
  if (user !== undefined && user !== ""){
    localStorage.setItem("user", user);
  }
    }, [user]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);


  async function makeRequestWithRetry(maxRetries = 3) {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        const response = await axios.post(
          `${urlPadrao}/user/login`,
          { email: email, password: password },
          {}
        );
        return response;
      } catch (error) {
        if (error.code === 'ECONNRESET') {
          console.log('Connection reset, retrying...');
          retries++;
        } else {
          throw error;
        }
      }
    }
    throw new Error(`Failed to establish connection after ${maxRetries} retries.`);
  }


  //Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);  

      const response = await(makeRequestWithRetry());
      setUser(JSON.stringify(response.data));
      
      navigate(response.data.page_default, { replace: false });

    } catch (err) {
      if (!err?.response) {
        setLoading(false);
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setLoading(false);
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setLoading(false);
        setErrMsg("Unauthorized");
        alert("Login Falhou");
      } else {
        setLoading(false);
        setErrMsg("Login Failed");
        alert("Login Falhou");
      }
      errRef.current.focus();
    }
  };

  if (!loading) {
    return (
      <Fragment>
        <div className="zle_box_login" >

          <div className="zle_login_x"> </div>

            <div  className="card p-5 zle_login" >    
                <img src={LogoZletric}  alt=""   width="100%" className="logo"  />
       
              <FormGroup>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscrenn"}  aria-live="assertive" >{errMsg} </p>
                <form onSubmit={handleSubmit}>
                  <Label htmlFor="email" className="label fw-bolder">
                    Email
                  </Label>
                  <Input className="mb-3" label="Email" id="email" type="email" ref={userRef} value={email} autoComplete="off" onChange={(e) => setEmail(e.target.value)} required
                  />
                  <Label htmlFor="password" className="label fw-bolder">
                    Senha
                  </Label>
                  <Input
                    className="mb-5" label="Password" id="password"
                    value={password} autoComplete="off" onChange={(e) => setPassword(e.target.value)} required type="password"
                  />

                  <div >
                    <Button
                      type="submit" className="entrar mb-4 w-50" size="lg" >
                      <b>Login</b>
                    </Button>
                  </div>
                </form>
              </FormGroup>

              <div className="widget-subheading">System Version: {process.env.REACT_APP_VERSION}</div> 
              
            </div>

            <div className="zle_login_x"> </div>

            
         
          </div>
 


      </Fragment>
    );
  } else {
    return (
      <div className="loading">
        <b>Efetuando login...</b>
        <InfinitySpin width="200" color="#4fa94d" />
        <p>Solução inteligente para recarga de veículos elétricos</p>
      </div>
    );
  }
}
