import React, { Fragment } from "react";
import Login from "./login";

const Logins = ({ match }) => (
  <Fragment>
    <Login></Login>
  </Fragment>
);

export default Logins;
